jQuery(function ($) {
  $(document).on("change", "#people_dt_dt_json_struct_id", function (evt) {
    var struct_id = $("#people_dt_dt_json_struct_id option:selected").val();
    var id = $("#people_dt_people_dt_id").val();
    $("#people_dt_data").empty();
    $.ajax({
      url: "ajax_dt_json_struct",
      type: "GET",
      data: "&dt_json_struct_id=" + struct_id + "&id=" + id,
    });
  });

  // SPG CONTROL
  // COUNT
  $(document).on("change", "#people_dt_data_spg_macro_counts", function (evt) {
    if ($(this).val() == 0) {
      $("#people_dt_data_spg_micro_motility_p").val(0);
      $("#people_dt_data_spg_micro_motility_im").val(0);
      $("#people_dt_data_spg_micro_motility_np").val(0);
      $("#people_dt_data_spg_micro_motility_p_f").val(0);
      $("#people_dt_data_spg_micro_morph_normal").val(0);
      $("#people_dt_data_spg_micro_morph_an_head").val(0);
      $("#people_dt_data_spg_micro_morph_an_tail").val(0);
      $("#people_dt_data_spg_micro_morph_amorphous").val(0);
    }
  });
  // MOTILITY
  $(document).on("change", "#people_dt_data_spg_micro_motility_p", function (
    evt
  ) {
    var a = $("#people_dt_data_spg_micro_motility_im");
    var b = $("#people_dt_data_spg_micro_motility_np");
    $(this).parent().removeClass("has-error");
    a.parent().removeClass("has-error");
    b.parent().removeClass("has-error");
    $(this).next("p").text("");
    a.next("p").text("");
    b.next("p").text("");
    if (a.val() != "" && b.val() != "") {
      var sum = parseInt($(this).val()) + parseInt(a.val()) + parseInt(b.val());
      if (sum != 100) {
        $(this).parent().addClass("has-error");
        a.parent().addClass("has-error");
        b.parent().addClass("has-error");
        $(this)
          .next("p")
          .text(
            "La somma di questi tre valori deve essere uguale a 100, attualmente è " +
              sum
          );
      }
    }
  });
  $(document).on("change", "#people_dt_data_spg_micro_motility_np", function (
    evt
  ) {
    var a = $("#people_dt_data_spg_micro_motility_im");
    var b = $("#people_dt_data_spg_micro_motility_p");
    $(this).parent().removeClass("has-error");
    a.parent().removeClass("has-error");
    b.parent().removeClass("has-error");
    $(this).next("p").text("");
    a.next("p").text("");
    b.next("p").text("");
    if (a.val() != "" && b.val() != "") {
      var sum = parseInt($(this).val()) + parseInt(a.val()) + parseInt(b.val());
      if (sum != 100) {
        $(this).parent().addClass("has-error");
        a.parent().addClass("has-error");
        b.parent().addClass("has-error");
        $(this)
          .next("p")
          .text(
            "La somma di questi tre valori deve essere uguale a 100, attualmente è " +
              sum
          );
      }
    }
  });
  $(document).on("change", "#people_dt_data_spg_micro_motility_im", function (
    evt
  ) {
    var a = $("#people_dt_data_spg_micro_motility_np");
    var b = $("#people_dt_data_spg_micro_motility_p");
    $(this).parent().removeClass("has-error");
    a.parent().removeClass("has-error");
    b.parent().removeClass("has-error");
    $(this).next("p").text("");
    a.next("p").text("");
    b.next("p").text("");
    if (a.val() != "" && b.val() != "") {
      var sum = parseInt($(this).val()) + parseInt(a.val()) + parseInt(b.val());
      if (sum != 100) {
        $(this).parent().addClass("has-error");
        a.parent().addClass("has-error");
        b.parent().addClass("has-error");
        $(this)
          .next("p")
          .text(
            "La somma di questi tre valori deve essere uguale a 100, attualmente è " +
              sum
          );
      }
    }
  });
  // MORPHOLOGY
  $(document).on("change", "#people_dt_data_spg_micro_morph_normal", function (
    evt
  ) {
    var a = $("#people_dt_data_spg_micro_morph_an_head");
    var b = $("#people_dt_data_spg_micro_morph_an_tail");
    var c = $("#people_dt_data_spg_micro_morph_amorphous");
    $(this).parent().removeClass("has-error");
    a.parent().removeClass("has-error");
    b.parent().removeClass("has-error");
    c.parent().removeClass("has-error");
    $(this).next("p").text("");
    a.next("p").text("");
    b.next("p").text("");
    c.next("p").text("");
    if (a.val() != "" && b.val() != "" && c.val() != "") {
      var sum =
        parseInt($(this).val()) +
        parseInt(a.val()) +
        parseInt(b.val()) +
        parseInt(c.val());
      if (sum != 100) {
        $(this).parent().addClass("has-error");
        a.parent().addClass("has-error");
        b.parent().addClass("has-error");
        c.parent().addClass("has-error");
        $(this)
          .next("p")
          .text(
            "La somma di questi quattro valori deve essere uguale a 100, attualmente è " +
              sum
          );
      }
    }
  });
  $(document).on("change", "#people_dt_data_spg_micro_morph_an_head", function (
    evt
  ) {
    var a = $("#people_dt_data_spg_micro_morph_normal");
    var b = $("#people_dt_data_spg_micro_morph_an_tail");
    var c = $("#people_dt_data_spg_micro_morph_amorphous");
    $(this).parent().removeClass("has-error");
    a.parent().removeClass("has-error");
    b.parent().removeClass("has-error");
    c.parent().removeClass("has-error");
    $(this).next("p").text("");
    a.next("p").text("");
    b.next("p").text("");
    c.next("p").text("");
    if (a.val() != "" && b.val() != "" && c.val() != "") {
      var sum =
        parseInt($(this).val()) +
        parseInt(a.val()) +
        parseInt(b.val()) +
        parseInt(c.val());
      if (sum != 100) {
        $(this).parent().addClass("has-error");
        a.parent().addClass("has-error");
        b.parent().addClass("has-error");
        c.parent().addClass("has-error");
        $(this)
          .next("p")
          .text(
            "La somma di questi quattro valori deve essere uguale a 100, attualmente è " +
              sum
          );
      }
    }
  });
  $(document).on("change", "#people_dt_data_spg_micro_morph_an_tail", function (
    evt
  ) {
    var a = $("#people_dt_data_spg_micro_morph_normal");
    var b = $("#people_dt_data_spg_micro_morph_an_head");
    var c = $("#people_dt_data_spg_micro_morph_amorphous");
    $(this).parent().removeClass("has-error");
    a.parent().removeClass("has-error");
    b.parent().removeClass("has-error");
    c.parent().removeClass("has-error");
    $(this).next("p").text("");
    a.next("p").text("");
    b.next("p").text("");
    c.next("p").text("");
    if (a.val() != "" && b.val() != "" && c.val() != "") {
      var sum =
        parseInt($(this).val()) +
        parseInt(a.val()) +
        parseInt(b.val()) +
        parseInt(c.val());
      if (sum != 100) {
        $(this).parent().addClass("has-error");
        a.parent().addClass("has-error");
        b.parent().addClass("has-error");
        c.parent().addClass("has-error");
        $(this)
          .next("p")
          .text(
            "La somma di questi quattro valori deve essere uguale a 100, attualmente è " +
              sum
          );
      }
    }
  });
  $(document).on(
    "change",
    "#people_dt_data_spg_micro_morph_amorphous",
    function (evt) {
      var a = $("#people_dt_data_spg_micro_morph_normal");
      var b = $("#people_dt_data_spg_micro_morph_an_head");
      var c = $("#people_dt_data_spg_micro_morph_an_tail");
      $(this).parent().removeClass("has-error");
      a.parent().removeClass("has-error");
      b.parent().removeClass("has-error");
      c.parent().removeClass("has-error");
      $(this).next("p").text("");
      a.next("p").text("");
      b.next("p").text("");
      c.next("p").text("");
      if (a.val() != "" && b.val() != "" && c.val() != "") {
        var sum =
          parseInt($(this).val()) +
          parseInt(a.val()) +
          parseInt(b.val()) +
          parseInt(c.val());
        if (sum != 100) {
          $(this).parent().addClass("has-error");
          a.parent().addClass("has-error");
          b.parent().addClass("has-error");
          c.parent().addClass("has-error");
          $(this)
            .next("p")
            .text(
              "La somma di questi quattro valori deve essere uguale a 100, attualmente è " +
                sum
            );
        }
      }
    }
  );
});
