jQuery(function ($) {
  $(document).on("change", "#pma_culture_medium_culture_medium_id", function (
    evt
  ) {
    var culture_medium_id = $(
      "#pma_culture_medium_culture_medium_id option:selected"
    ).val();
    $.ajax({
      url: "ajax_culture_medium_lot",
      type: "GET",
      data: "&culture_medium_id=" + culture_medium_id,
    });
  });
});
