jQuery(function ($) {
  $(document).on("change", "#sm_field_condition_class_name", function (evt) {
    var value = $("#sm_field_condition_class_name option:selected").val();
    $("#sm_field_condition_field_name").empty();
    $.ajax({
      url: "/sm_field_conditions/ajax_field_names",
      type: "GET",
      data: "&class_name=" + value,
    });
  });
});
