jQuery(function ($) {
  $(document).on("load change", function () {
    $(".dose .best_in_place").on("ajax:success", function () {
      var total_dose = 0;
      pd = $(this).data("pdId");
      document.querySelectorAll(".pd_" + pd).forEach(function (act) {
        if (act.textContent != "-") {
          total_dose = total_dose + parseInt(act.textContent);
        }
      });
      document.getElementById("dose_total_" + pd).innerHTML = total_dose;
    });

    $(".pmd_info .best_in_place").on("ajax:success", function () {
      var total_pmd = 0;
      pmd = $(this).data("pmdId");
      document.querySelectorAll(".pmd_" + pmd).forEach(function (act) {
        if (act.textContent != "-") {
          total_pmd = total_pmd + parseInt(act.textContent);
        }
      });
      document.getElementById("total_" + pmd).innerHTML = total_pmd;
    });
  });
});
