jQuery(function ($) {
  $(document).ready(function () {
    if ($("#dt_json_struct_data").length) {
      prettyPrint($("#dt_json_struct_data"));
    }
  });

  $(document).on("change", "#dt_json_struct_data", function (evt) {
    prettyPrint($("#dt_json_struct_data"));
  });
});
