jQuery(function ($) {
  $(document).on(
    "change",
    "#pma_datum_physiological_anamnesis_weight",
    function (evt) {
      calc_bmi();
    }
  );

  $(document).on(
    "change",
    "#pma_datum_physiological_anamnesis_height",
    function (evt) {
      calc_bmi();
    }
  );

  function calc_bmi() {
    var w = $("#pma_datum_physiological_anamnesis_weight").val();
    var h = $("#pma_datum_physiological_anamnesis_height").val();
    var bmi_value;
    bmi_value = w / Math.pow(h / 100, 2);
    $("#pma_datum_physiological_anamnesis_bmi").val(bmi_value.toFixed(2));
  }
});
