jQuery(function($) {
  // Get parameter from url
  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  function set_details_info(master_id) {
    master_class = $('#master_data').attr('class').replace('master_','');
    detail_class = $('#detail_data').attr('class').replace('detail_','');
    var locale = getParameterByName('locale');
    $.ajax({
      url: "/set_details_info",
      type: "GET",
      data: 'master_id=' + master_id + '&master_class=' + master_class + '&detail_class=' + detail_class + '&locale=' + locale
    })
  }

  $(document).on('click', '#master_data > div.table-responsive > table > tbody#master_list > tr', function(event) {
    var master_id = this.id.replace('master_row_','');
    set_details_info(master_id);
    $("#master_list > tr").removeClass("table-warning");
    $(this).addClass("table-warning");
  });
});
