jQuery(function ($) {
  $(document).on("click", ".calendar_items_filter", function (event) {
    var calendar_filter_id = this.id.replace("filter_items_id_", ".cd_type_id_items_");
    if ($(this).hasClass("grayout")) {
      $(this).removeClass("grayout");
    } else {
      $(this).addClass("grayout");
    }
    $(calendar_filter_id).toggleClass("filtered-items");
  });
  $(document).on("click", ".calendar_clinic_filter", function (event) {
    var calendar_filter_id = this.id.replace("filter_clinic_id_", ".cd_type_id_hospital_");
    if ($(this).hasClass("grayout")) {
      $(this).removeClass("grayout");
    } else {
      $(this).addClass("grayout");
    }
    $(calendar_filter_id).toggleClass("filtered-clinic");
  });
  $(document).on("click", ".calendar_filter", function (event) {
    var calendar_filter_id = this.id.replace("filter_id_", ".cd_type_id_");
    if ($(this).hasClass("grayout")) {
      $(this).removeClass("grayout");
    } else {
      $(this).addClass("grayout");
    }
    $(calendar_filter_id).toggleClass("filtered-type");
  });
  $(document).on("keyup", "#searchValue", function (event) {
    event.preventDefault();
    let calendar_elements = document.querySelectorAll(".cd_element");
    let search_query = document.getElementById("searchValue").value;
    for (var i = 0; i < calendar_elements.length; i++) {
      if (
        calendar_elements[i].innerText
          .toLowerCase()
          .includes(search_query.toLowerCase())
      ) {
        calendar_elements[i].classList.remove("filtered");
      } else {
        calendar_elements[i].classList.add("filtered");
      }
    }
  });
});
