jQuery(function ($) {
  $(document).ready(function () {
    if ($("#pma_json_struct_physiological_anamnesis").length) {
      prettyPrint($("#pma_json_struct_physiological_anamnesis"));
      prettyPrint($("#pma_json_struct_family_history"));
      prettyPrint($("#pma_json_struct_pathological_anamnesis"));
      prettyPrint($("#pma_json_struct_summary"));
    }
  });

  $(document).on(
    "change",
    "#pma_json_struct_physiological_anamnesis",
    function (evt) {
      prettyPrint($("#pma_json_struct_physiological_anamnesis"));
    }
  );
  $(document).on("change", "#pma_json_struct_family_history", function (evt) {
    prettyPrint($("#pma_json_struct_family_history"));
  });
  $(document).on("change", "#pma_json_struct_pathological_anamnesis", function (
    evt
  ) {
    prettyPrint($("#pma_json_struct_pathological_anamnesis"));
  });
  $(document).on("change", "#pma_json_struct_summary", function (evt) {
    prettyPrint($("#pma_json_struct_summary"));
  });
});
