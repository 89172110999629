import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import italian from 'flatpickr/dist/l10n/it.js';
import confirmDate from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';

$(document).on('turbolinks:load', function() {
  // Generic
  flatpickr('.datetimepicker', {
    locale: 'it',
    enableTime: true,
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    defaultHour: new Date().getHours(),
    defaultMinute: new Date().getMinutes(),
    minuteIncrement: 1,
    time_24hr: true,
    altInput: true,
    altFormat: 'F j, Y H:i',
    dateFormat: 'Z',
  });

  flatpickr('.datepicker', {
    locale: 'it',
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Z',
  });

  // START_TIME END_TIME 1m
  let start_time = flatpickr('#start_time', {
    locale: 'it',
    enableTime: true,
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    defaultHour: new Date().getHours(),
    defaultMinute: new Date().getMinutes(),
    minuteIncrement: 1,
    time_24hr: true,
    altInput: true,
    altFormat: 'F j, Y H:i',
    dateFormat: 'Z',
    maxDate: $('#end_time').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      end_time.set('minDate', dateStr);
    },
  });

  let end_time = flatpickr('#end_time', {
    locale: 'it',
    enableTime: true,
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    defaultHour: new Date().getHours(),
    defaultMinute: new Date().getMinutes(),
    minuteIncrement: 1,
    time_24hr: true,
    altInput: true,
    altFormat: 'F j, Y H:i',
    dateFormat: 'Z',
    minDate: $('#start_time').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      start_time.set('maxDate', dateStr);
    },
  });

  // START_TIME END_TIME 15m
  let start_time_inc_15 = flatpickr('#start_time_inc_15', {
    locale: 'it',
    enableTime: true,
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    minuteIncrement: 15,
    time_24hr: true,
    altInput: true,
    altFormat: 'F j, Y H:i',
    dateFormat: 'Z',
    maxDate: $('#end_time_inc_15').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
    end_time_inc_15.set('minDate', dateStr);
    },
  });

  let end_time_inc_15 = flatpickr('#end_time_inc_15', {
    locale: 'it',
    enableTime: true,
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    minuteIncrement: 15,
    time_24hr: true,
    altInput: true,
    altFormat: 'F j, Y H:i',
    dateFormat: 'Z',
    minDate: $('#start_time_inc_15').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      start_time_inc_15.set('maxDate', dateStr);
    },
  });
  // CLOSING_FROM CLOSING_TO
  let closing_from = flatpickr('#closing_from', {
    locale: 'it',
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Z',
    maxDate: $('#closing_to').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      closing_to.set('minDate', dateStr);
    },
  });

  let closing_to = flatpickr('#closing_to', {
    locale: 'it',
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Z',
    minDate: $('#closing_from').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      closing_from.set('maxDate', dateStr);
    },
  });

  // START_FROM START_TO
  let start_from = flatpickr('#start_from', {
    locale: 'it',
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Z',
    maxDate: $('#start_to').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      start_to.set('minDate', dateStr);
    },
  });

  let start_to = flatpickr('#start_to', {
    locale: 'it',
    plugins: [
      new confirmDate({
        confirmIcon: "<i class='fas fa-check'></i>",
        confirmText: '',
        showAlways: true,
      }),
    ],
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Z',
    minDate: $('#start_from').attr('value'),
    onChange: function(selectedDates, dateStr, instance) {
      start_from.set('maxDate', dateStr);
    },
  });
});
