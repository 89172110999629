// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
// require("channels");
require("trix");
require("@rails/actiontext");
require("plugins/flatpickr.js.erb");
require("chosen-js");
require("jquery-treegrid/js/jquery.treegrid.min.js");
require("bootstrap");
require("expose-loader?bootbox!bootbox");
require("expose-loader?toastr!toastr");

require("src/best_in_place.js.erb");
require("src/dt_json_structs.js");
require("src/master_detail.js");
require("src/calendars.js");
require("src/pagy.js.erb");
require("src/people_dts.js");
require("src/pma_couples.js");
require("src/pma_culture_media.js");
require("src/pma_data.js");
require("src/pma_json_structs.js");
require("src/pma_monitoring_checks.js");
require("src/pma_plastic_materials.js");
require("src/sm_field_conditions");

require("stylesheets/application.scss");

document.addEventListener("turbolinks:load", () => {
  pagy_elements = document.querySelectorAll(".pagination");
  pagy_elements.forEach((p) => {
    p.classList.add("pagination-sm");
    p.classList.add("text-uppercase");
    p.classList.add("font-weight-bold");
  });
  $(".best_in_place").best_in_place();
  $(".tag-tooltip").tooltip({
    html: true,
    trigger: "hover",
    boundary: "window",
  });
  $(".tree-expanded").treegrid({
    expanderExpandedClass: "put_chevron_up",
    expanderCollapsedClass: "put_chevorn_down",
    initialState: "expanded",
  });
  $(".tree-collapsed").treegrid({
    expanderExpandedClass: "put_chevron_up",
    expanderCollapsedClass: "put_chevron_down",
    initialState: "collapsed",
  });
  $(".popize").popover({
    html: true,
    offset: 5,
    placement: "right",
  });
  // Select
  $(".chosen-select").chosen({
    no_results_text: "Nessuna corrispondenza con:",
    allow_single_deselect: true,
    placeholder_text_multiple: " ",
    search_contains: true,
    placeholder_text_single: " ",
  });
  $(".chosen-select").on("chosen:showing_dropdown", function (event) {
    var chosen_container = $(event.target).next(".chosen-container");
    var dropdown = chosen_container.find(".chosen-drop");
    var dropdown_top = dropdown.offset().top - $(window).scrollTop();
    var dropdown_height = dropdown.height();
    var viewport_height = $(window).height();

    if (dropdown_top + dropdown_height > viewport_height) {
      chosen_container.addClass("chosen-drop-up");
    }
  });
  $(".chosen-select").on("chosen:hiding_dropdown", function (event) {
    $(event.target).next(".chosen-container").removeClass("chosen-drop-up");
  });
  $("#main").on("scroll", function () {
    if ($(this).scrollTop() > 50) {
      $("#back-to-top").fadeIn("slow");
    } else {
      $("#back-to-top").fadeOut("slow");
    }
  });
  $("#back-to-top").on("click", function () {
    location.hash = "";
    $("#back-to-top").tooltip("hide");
    $("#main").animate(
      {
        scrollTop: 0,
      },
      800
    );
    return false;
  });

  $(document).on("change", "#acl_detail_class_name", function () {
    var value = $("#acl_detail_class_name option:selected").val();
    $("#acl_detail_field_name").empty();
    $.ajax({
      url: "/acl_details/ajax_field_names",
      type: "GET",
      data: "&class_name=" + value,
    });
  });

  $(document).on("click", ".reset_search_form", function () {
    resetForm($("#search_form"));
    $("select").val("").trigger("chosen:updated");
  });

  $(document).on("click", ".btn, .pagination", function (e) {
    e.stopPropagation();
  });
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js").then((reg) => {
      console.log("Service worker registered.", reg);
    });
  });
}

// Reset form for filter
function resetForm($form) {
  $form
    .find(
      "input[type=number], input:text, input.flatpickr-input, input:password, input:file, select, textarea"
    )
    .val("");
  $form
    .find("input:radio, input:checkbox")
    .removeAttr("checked")
    .removeAttr("selected");
}

// Show ON on filters-button if filter is active
$(function () {
  var fields = $("#search_form").serializeArray();
  is_blank = true;
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].name != "locale" && fields[i].name != "utf8") {
      if (fields[i].value) {
        is_blank = false;
      }
    }
  }
  if (is_blank) {
    //$('#filters-button').append(': OFF');
  } else {
    $("#filters-button").append(": ON");
  }
});

// JSON pretty
function prettyPrint(obj) {
  var ugly_json = JSON.parse(obj.val());
  var pretty_json = JSON.stringify(ugly_json, undefined, 2);
  obj.val(pretty_json);
  enlargeTextarea(obj);
}

// Enlarge TextArea
function enlargeTextarea(obj) {
  obj[0].setAttribute(
    "style",
    "height:" + obj[0].scrollHeight + "px;overflow-y:hidden;"
  );
  obj.on("input", function () {
    obj[0].style.height = "auto";
    obj[0].style.height = obj[0].scrollHeight + "px";
  });
}
