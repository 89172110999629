jQuery(function ($) {
  $(document).on(
    "change",
    "#pma_plastic_material_plastic_material_id",
    function (evt) {
      var plastic_material_id = $(
        "#pma_plastic_material_plastic_material_id option:selected"
      ).val();
      $.ajax({
        url: "ajax_plastic_material_lot",
        type: "GET",
        data: "&plastic_material_id=" + plastic_material_id,
      });
    }
  );
});
